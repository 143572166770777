'use client';

import clsx from 'clsx';
import { motion } from 'framer-motion';

import { memo } from 'react';

import { useWindowSize } from '../../hooks/useWindowSize';

const TILE_SIZE = 110;

/**
 * This is just a 2x6 pattern I found in the design
 * since I didn't want to create fully random
 * animation because it's not trivial to find
 * when neigbors are intersecting to make
 * a smooth animation
 *
 * In this case:
 * 0 - are empty
 * 1 - animated box in the first half of a cycle
 * 2 - animated box in the second half of a cycle
 */
const box = [
    // row
    1, 2,
    // row
    0, 0,
    // row
    2, 0,
    // row
    0, 1,
    // row
    0, 2,
];

/**
 * Placement of the box above to be like in the design
 */
const boxStartCoords: [number, number][] = [
    [4, -2],
    [-2, -7],
    [1, 3],
    [-5, -2],
];

/**
 * Utility object to enrich start coords with end coords
 * for easier comparison with known column and row
 */
const boxCoords: [number, number, number, number][] = boxStartCoords.map(([x, y]) => [
    x,
    y,
    x + 1,
    y + 4,
]);

/**
 * Utility to have a delay for every box we defined above
 * Make it pre-defined to have all tiles in a box have the same delay
 */
const boxDelays = boxCoords.reduce(
    (acc, coords) => {
        const key = JSON.stringify(coords);
        acc[key] = Math.floor(Math.random() * 10) / 10;
        return acc;
    },
    {} as Record<string, number>,
);

function findBox(col: number, row: number) {
    return boxCoords.findIndex(([xStart, yStart, xEnd, yEnd]) => {
        const fitCol = col >= xStart && col <= xEnd;
        const fitRow = row >= yStart && row <= yEnd;

        return fitCol && fitRow;
    });
}

enum TileType {
    empty,
    first,
    second,
}

function getTileType(col: number, row: number) {
    const boxIndex = findBox(col, row);
    const boxForTile = boxCoords[boxIndex];

    const reverse = boxIndex % 2 === 0;

    if (boxForTile == null) {
        return { tile: TileType.empty, boxId: '' };
    }

    const [x, y] = boxForTile;

    const boxX = col - x;
    const boxY = row - y;

    const boxTile = box[boxX + boxY * 2];
    const boxId = JSON.stringify(boxForTile);

    switch (boxTile) {
        case 0:
            return { tile: TileType.empty, boxId, boxIndex };
        case 1:
            return { tile: reverse ? TileType.first : TileType.second, boxId };
        case 2:
            return { tile: reverse ? TileType.second : TileType.first, boxId };
        default:
            return { tile: TileType.empty, boxId };
    }
}

function EmptyTile() {
    return <div className={clsx('border-t border-light')} style={{ height: `${TILE_SIZE}px` }} />;
}

interface AnimatedTileProps {
    loopDuration: number;
    delay: number;
}

function AnimatedTile({ loopDuration, delay }: AnimatedTileProps) {
    /**
     * Make it more dynamic by having every tile it's own random delay
     */
    const localDelay = Math.floor(Math.random() * 10) / 10;
    const delayRatio = localDelay / loopDuration;
    return (
        <div className={clsx('border-t border-light flex')} style={{ height: `${TILE_SIZE}px` }}>
            <motion.div
                className={clsx('bg-tertiary flex flex-1')}
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1, 1, 0] }}
                transition={{
                    type: 'keyframes',
                    duration: loopDuration,
                    times: [delayRatio, 0.1 + delayRatio, 0.9 - delayRatio, 1 - delayRatio],
                    repeat: Infinity,
                    repeatDelay: loopDuration,
                    delay,
                }}
            />
        </div>
    );
}

export const TilesBackground = memo(function TilesBackground() {
    const { width, height } = useWindowSize();

    let tilesHorizontalCount = Math.ceil(width / TILE_SIZE);

    if (tilesHorizontalCount % 2 === 0) {
        tilesHorizontalCount += 1;
    }

    const colsCenter = Math.floor(tilesHorizontalCount / 2);

    let tilesVerticalCount = Math.ceil(height / TILE_SIZE);

    if (tilesVerticalCount % 2 !== 0) {
        tilesVerticalCount += 1;
    }

    const rowsCenter = tilesVerticalCount / 2;

    const panSize = {
        width: tilesHorizontalCount * TILE_SIZE,
        height: tilesVerticalCount * TILE_SIZE,
    };

    const insets = {
        left: (-1 * (panSize.width - width)) / 2,
        top: (-1 * (panSize.height - height)) / 2,
    };

    const cols = Array.from({ length: tilesHorizontalCount }).fill(null);
    const rows = Array.from({ length: tilesVerticalCount }).fill(null);

    const loopDuration = 5;

    return (
        <div className="absolute flex flex-row -z-50" style={{ ...panSize, ...insets }}>
            {cols.map((_c, colIndex) => (
                <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${colIndex}`}
                    className="border-r border-light flex flex-col h-full"
                    style={{ width: `${TILE_SIZE}px` }}
                >
                    {rows.map((_r, rowIndex) => {
                        const { tile, boxId } = getTileType(
                            colIndex - colsCenter,
                            rowIndex - rowsCenter,
                        );

                        if (tile === TileType.empty) {
                            return (
                                <EmptyTile
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${colIndex}:${rowIndex}`}
                                />
                            );
                        }
                        if (tile === TileType.first) {
                            return (
                                <AnimatedTile
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${colIndex}:${rowIndex}`}
                                    loopDuration={loopDuration}
                                    delay={0 + (boxDelays[boxId] ?? 0)}
                                />
                            );
                        }

                        if (tile === TileType.second) {
                            return (
                                <AnimatedTile
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`${colIndex}:${rowIndex}`}
                                    loopDuration={loopDuration}
                                    delay={loopDuration + (boxDelays[boxId] ?? 0)}
                                />
                            );
                        }

                        return null;
                    })}
                </div>
            ))}
        </div>
    );
});
